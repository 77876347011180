import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Photo from "./Photo";
import { useTranslation } from "../../lib/i18n";

const PortofolioDetailSection = (props) => {
    const { t } = useTranslation();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    const { project, } = props;

    if (!project) return null;
    
    const PHOTOS = project.images.map(function (photo) {
        return {
            src: require(`../../assets/images/portfolio/${photo}`),
            width: 3,
            height: 5,
        }
    });
    console.log("PHOTOS", PHOTOS);
    return (
        <>
            <section className="commonSection porfolioDetail">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-md-6">

                            <Gallery photos={PHOTOS} onClick={openLightbox} direction={"column"} renderImage={Photo} />
                            <ModalGateway>
                                {viewerIsOpen ? (
                                    <Modal onClose={closeLightbox}>
                                        <Carousel
                                            currentIndex={currentImage}
                                            views={PHOTOS.map(x => ({
                                                ...x,
                                                srcset: x.srcSet,
                                                caption: x.title
                                            }))}
                                        />
                                    </Modal>
                                ) : null}
                            </ModalGateway>

                        </div>
                        <div className="col-lg-6 col-sm-6 col-md-6">
                            <div className="singlePortfoio_content">
                                <h3>{t('Portofolio.Details.Description')}</h3>
                                <p>
                                    {project.description}
                                </p>
                            </div>
                            <div className="singlePortfoio_content">
                                <h3>{t('Portofolio.Details.Challenge')}</h3>
                                <p>
                                    {project.challenge}
                                </p>
                            </div>
                            <div className="singlePortfoio_content">
                                <h4>{t('Portofolio.Details.Client')}</h4>
                                <p>{project.client}</p>
                            </div>
                            <div className="singlePortfoio_content">
                                <h4>{t('Portofolio.Details.Category')}</h4>
                                <p><a href="#">{project.category}</a></p>
                            </div>
                            <div className="singlePortfoio_content">
                                <h4>{t('Portofolio.Details.Date')}</h4>
                                <p>{project.date}</p>
                            </div>
                            <div className="singlePortfoio_content">
                                <h4>{t('Portofolio.Details.Follow')}</h4>
                                <ul>
                                    <li><a href={project.facebook}>{t('Social.Facebook')}</a></li>
                                    <li><a href={project.twitter}>{t('Social.Twitter')}</a></li>
                                    <li><a href={project.youtube}>{t('Social.Youtube')}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="nextprevPagination">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6 col-xs-6 text-left">
                                        {project.prev ?
                                            <Link className="prevFolio" to={`/portofolio-detail/${project.prev}`}>
                                                <i className="fa fa-angle-left"></i>{t('Portofolio.Details.Prev')}
                                            </Link> : null
                                        }

                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-xs-6 text-right">
                                        {project.next ?
                                            <Link className="nextFolio" to={`/portofolio-detail/${project.next}`}>
                                                {t('Portofolio.Details.Next')} <i className="fa fa-angle-right"></i>
                                            </Link> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PortofolioDetailSection;
