import React, {Component} from 'react';
import DisclaimerContent from "../components/DisclaimerContent";
import Footer from "../components/Footer";

export default class DisclaimerPage extends Component {
    render() {
        return (
            <>
                <DisclaimerContent/>
                <Footer/>
            </>
        );
    }
};
