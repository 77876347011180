import React, { Component } from 'react';
import SearchForm from "../../components/SearchForm";
import PopupMenu from "../../components/PopupMenu";
import Footer from "../../components/Footer";
import Journey from "../../components/Journey";
import AboutUs from "../../components/AboutUs";
import TeamBio from "../../components/TeamBio";
import GetYourQuote from "../../components/GetYourQuote";
import MissionVision from "../../components/MissionVision";
import Values from "../../components/Values";
import TeamBioDescription from "../../components/TeamBioDescription";
import ConnectWithUs from "../../components/ConnectWithUs";
import { Trans } from '../../lib/i18n';

export default class AboutPage extends Component {
    render() {
        return (
            <>
                <SearchForm />
                <AboutUs />
                <TeamBio />
                <MissionVision />
                <Values />
                <TeamBioDescription />
                <Journey />
                <GetYourQuote title={<Trans i18nKey="AboutUs.GetQuote.Title" />} />
                <ConnectWithUs buttonText={<Trans i18nKey="AboutUs.Contact.Button" />} title={<Trans i18nKey="AboutUs.Contact.Title" />} />
                <Footer />

            </>
        );
    }
};
