import React from 'react';
import mountains from "../../assets/images/about/mountains.png";
import {Link} from "react-router-dom";

const ConnectWithUs = (props) => {
    const {title, buttonText = 'Get your quote now', description} = props;
    return (
        <>
            <section className="commonSection commonSectionAbout commonSectionBackground"
                     style={{background: `#000`,backgroundImage: `url(${mountains})`}}>
                <div className="container">
                    <div className="row d-md-flex">
                        <div className="col-lg-9 col-sm-8 col-md-9">
                            <h2 className="sec_title white">{title}</h2>
                            {description && <p className="sec_desc white">{description}</p>}
                        </div>
                        <div className="col-lg-3  col-sm-4 col-md-3 text-right d-md-flex">
                            <Link  className="common_btn m-auto" to="/contact"><span>{buttonText}</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ConnectWithUs;
