import React, { Component } from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import PageTitle from "../../components/PageTitle";
import OurServiceDetail from "../../components/OurServiceDetail";
import image1 from "../../assets/images/consulting/consultancy.png";
import image2 from "../../assets/images/consulting/reason-to-hire-a-consultant.png";
import image3 from "../../assets/images/consulting/real-cost-of-hiring-a-consultant.png";
import image4 from "../../assets/images/consulting/what-consultants-can-mean.png";
import { Trans, useTranslation } from '../../lib/i18n';

const img = { image1, image2, image3, image4 };

const ConsultingPage = () => {
    const { t } = useTranslation()
    return (
        <>
            <SearchForm />
            <PageTitle
                title={<Trans i18nKey='Consulting.Title' />}
                subTitle={<Trans i18nKey='Consulting.SubTitle' />}
                sectionClassName="serviceDetailBackgroundBlack"
            />

            {t('Consulting.Services', { returnObjects: true })?.map((item, index) => <OurServiceDetail key={index} classLeft={item.ClassLeft} classRight={item.ClassRight}
                childrenLeft={<>
                    {item.Title && <h2 className="sec_title">{t(item.Title)}</h2>}
                    <Trans i18nKey={item.Description} />
                </>}
                childrenRight={<>
                    {item.Img &&
                        <img src={img[item.Img]} className={"img_full_width"} alt="" />
                    }
                </>}
            />)}

            <GetYourQuote
                buttonText={<Trans i18nKey="Common.Let's start" />}
                title={<Trans i18nKey="Common.Let's start button" />}
                description={<Trans i18nKey="Consulting.Get your Quote.Description" />} />
            <Footer />
        </>
    );
};

export default ConsultingPage;
