import React from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import OurServiceDetail from "../../components/OurServiceDetail";
import PageTitle from "../../components/PageTitle";
import ReportsOfCyberRisks from "../../components/Audit/ReportsOfCyberRisks";

import image1 from "../../assets/images/audit/it-audit.png";
import image2 from "../../assets/images/audit/web-applications-security-report.png";
import image3 from "../../assets/images/audit/cost-assesment.png";
import image4 from "../../assets/images/audit/how-it-audit-work.png";

import { Trans, useTranslation } from '../../lib/i18n';

const img = {
    image1,
    image2,
    image3,
    image4,
}

const AuditPage = () => {
    const { t } = useTranslation()
    return (
        <>
            <SearchForm />
            <PageTitle
                title={<Trans i18nKey='Audit.Title' />}
                subTitle={<Trans i18nKey='Audit.SubTitle' />}
                sectionClassName="serviceDetailBackgroundBlack"
            />

            {t('Audit.Services', { returnObjects: true })?.map((item, index) => <OurServiceDetail key={index} classLeft={item.ClassLeft} classRight={item.ClassRight}
                childrenRight={<>
                    {item.Title && <h2 className="sec_title">{t(item.Title)}</h2>}
                    <p className="sec_desc">
                        <Trans i18nKey={item.Description} />
                    </p></>}
                childrenLeft={<>
                    {item.Img &&
                        <img src={img[item.Img]} className={"img_full_width"} alt="" />
                    }
                </>}
            />)}

            <ReportsOfCyberRisks />

            <GetYourQuote title={<Trans i18nKey="Common.Let's start" />} />
            <Footer />
        </>
    );
};

export default AuditPage;
