import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from '../../lib/i18n';

const PopupMenu = () => {
    const { $ } = window;
    const { t } = useTranslation();

    const onRouteClick = () => {
        $('#close-popup').click();
    };

    return (
        <>
            <div className="popup popup__menu">
                <a href="" id="close-popup" className="close-popup" />
                <div className="container mobileContainer">
                    <div className="row">
                        <div className="col-lg-12 text-left">
                            {/*<div className="logo2">*/}
                            {/*    <Link to="/"><img src={logo} alt="NKM logo"/></Link>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="popup-inner">
                                <div className="dl-menu__wrap dl-menuwrapper">
                                    {/*<nav>*/}

                                    <ul className="dl-menu dl-menuopen">
                                        <li>
                                            <Link to="/" onClick={onRouteClick}>{t('PopupMenu.Home')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/about" onClick={onRouteClick}>{t('PopupMenu.About')}</Link>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link to="#" >{t('PopupMenu.Services')}</Link>
                                            <ul className="dl-submenu">
                                                <li><Link to="/services/it-development">{t('PopupMenu.services sub menu.0')}</Link></li>
                                                <li><Link to="/services/audit">{t('PopupMenu.services sub menu.1')}</Link></li>
                                                <li><Link to="/services/maintenance">{t('PopupMenu.services sub menu.2')}</Link></li>
                                                <li><Link to="/services/consulting">{t('PopupMenu.services sub menu.3')}</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/portofolio" onClick={onRouteClick}>{t('PopupMenu.Portofolio')}</Link></li>
                                        <li><Link to="/contact" onClick={onRouteClick}>{t('PopupMenu.Contact')}</Link></li>
                                    </ul>
                                    {/*</nav>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-xs-12 text-left">
                            <ul className="footer__contacts text-left">
                                <li><Trans i18nKey='Common.Email' /></li>
                                <li><Trans i18nKey='Common.Address' /></li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-xs-12 col-xs-12">
                            <div className="popUp_social text-right">
                                <ul>
                                    <li><a href="https://twitter.com/hybrid_cyber"><i className="fa fa-twitter"></i>{t('Social.Twitter')}</a></li>
                                    <li><a href="https://www.facebook.com/Cyber-Hybrid-103051907856184/"><i className="fa fa-facebook-square"></i>{t('Social.Facebook')}</a></li>
                                    <li><a href=""><i className="fa fa-youtube-play"></i>{t('Social.Youtube')}</a></li>
                                    <li><a href="https://www.linkedin.com/company/31452855/"><i className="fa fa-linkedin"></i>{t('Social.Linkedin')}</a></li>
                                    <li><a href="https://www.instagram.com/nkm"><i className="fa fa-instagram"></i>{t('Social.Instagram')}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PopupMenu;
