import React from 'react';
import journey from "../../assets/images/journey.png";
import { Trans, useTranslation } from '../../lib/i18n';

const Journey = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="commonSection featured no-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 aboutPicture" style={{ backgroundImage: `url(${journey})` }}>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="row my-4">
                                        <div className="col-xs-2 col-md-2 col-lg-2 col-xl-3" />
                                        <div className="col-xs-8 col-md-8 col-lg-8 col-xl-6">
                                            <div className="features_content">
                                                <h2 className="sec_title"><Trans i18nKey="AboutUs.Jurney.Title" /></h2>

                                                {t('AboutUs.Jurney.Features', { returnObjects: true })?.map((item, index) => {
                                                    const currentNo = (index + 1) > 9 ? (index + 1) : `0${(index + 1)}`

                                                    return <div key={item.Date} className="singleFeature">
                                                        <div className="f_count">{currentNo}</div>
                                                        <h3><Trans i18nKey={item.Date} /></h3>

                                                        {item.Description && <p>
                                                            <Trans i18nKey={item.Description} />
                                                        </p>
                                                        }
                                                    </div>

                                                })}
                                            </div>
                                        </div>
                                        <div className="col-xs-2 col-md-2 col-lg-2 col-xl-3" />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Journey;
