import React from 'react';
import logo from "../../assets/images/logo-header.png";
import {Link} from 'react-router-dom';
import {useTranslation} from '../../lib/i18n';
import LocalizationFlag from '../LocalizationFlag';

function Header(props) {
    const {t} = useTranslation();

    return (
        <>
            <header className="header_01 fixedHeader flipInX black_color" id="header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2 col-sm-3 col-md-3 col-xs-8">
                            <div className="logo">
                                <Link to="/"><img src={logo} alt="NKM Finance logo"/></Link>
                            </div>
                        </div>
                        <div className="col-lg-8 col-sm-7 col-md-7 m-auto">
                            <nav className="mainmenu text-center">
                                <ul>
                                    <li>
                                        <Link to="/">{t('Header.Home')}</Link>
                                    </li>
                                    <li><Link to="/about">{t('Header.About')}</Link>

                                        <ul className="sub-menu">
                                            <li><Link to="/about/work-method">{t('Header.Work method')}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="#">{t('Header.Services')}</Link>

                                        <ul className="sub-menu">
                                            <li><Link to="/services/vbm">{t('Header.VBM')}</Link></li>
                                            <li><Link to="/services/consultancy">{t('Header.Consultancy')}</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/partners">{t('Header.Partners')}</Link></li>
                                    <li><Link to="/case-study">{t('Header.Case study')}</Link></li>
                                    <li><Link to="/contact">{t('Header.Contact')}</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-2 col-sm-2 col-md-2 col-xs-4 m-auto d-flex">
                            <LocalizationFlag/>

                            <div className="navigator text-right">

                                <Link to="#"
                                      className="menu mobilemenu hidden-sm hidden-md hidden-lg hidden-xs">
                                    <i className="mei-menu"/>
                                </Link>
                                <Link id="open-overlay-nav" className="menu hamburger" to="#">
                                    <i className="mei-menu"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
