import React, {Component} from 'react';


class OurServiceDetail extends Component {
    render() {
        const {childrenLeft, childrenRight,classLeft,classRight} = this.props
        return (
            <>
                <section className="commonSection no-padding">
                    <div className="container-fluid">
                        <div className="row d-md-flex">
                            <div className={`col-md-6 col-sm-12 ${classLeft} d-md-flex`}>
                                <div className="serviceDetailWrap m-auto">
                                    {childrenLeft}
                                </div>
                            </div>
                            <div className={`col-md-6 col-sm-12 ${classRight} d-md-flex`}>
                                <div className="serviceDetailWrap m-auto">
                                    {childrenRight}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default OurServiceDetail;