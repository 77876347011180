import React from 'react';
import test from "../../assets/images/team/2.png";
import fey from "../../assets/images/about/fey.jpg";
import raihan from "../../assets/images/about/raihan.jpg";
import tanweer from "../../assets/images/about/tanweer.jpg";
import cipri from "../../assets/images/team/4.png";
import { useTranslation } from '../../lib/i18n';

const TeamBio = () => {
    const { t } = useTranslation();

    return (
        <section className="commonSection" >
            <div className="container text-sm-center">

                <div className="row">
                    <div className="col-md-6 my-4">
                        <div className="team_bio_image">
                            <img src={fey} className="team_bio_image_container" alt="" />
                        </div>
                        <h4 className="sub_title">{t('AboutUs.Members.0.position')}</h4>
                        <h2>{t('AboutUs.Members.0.name')}</h2>

                        <p>{t('AboutUs.Members.0.description')}</p>
                    </div>
                    <div className="col-md-6 my-4">
                        <div className="team_bio_image_container">
                            <img src={raihan} className="team_bio_image_container" alt="" />
                        </div>
                        <h4 className="sub_title">{t('AboutUs.Members.1.position')}</h4>
                        <h2>{t('AboutUs.Members.1.name')}</h2>
                        <p>{t('AboutUs.Members.1.description')}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 my-4">
                        <div className="team_bio_image">
                            <img src={tanweer} className="team_bio_image_container" alt="" />
                        </div>
                        <h4 className="sub_title">{t('AboutUs.Members.2.position')}</h4>
                        <h2>{t('AboutUs.Members.2.name')}</h2>
                        <p>{t('AboutUs.Members.2.description')}</p>
                    </div>
                    <div className="col-md-6 my-4">
                        <div className="team_bio_image">
                            <img src={cipri} className="team_bio_image_container" alt="" />
                        </div>
                        <h4 className="sub_title">{t('AboutUs.Members.3.position')}</h4>
                        <h2>{t('AboutUs.Members.3.name')}</h2>
                        <p>{t('AboutUs.Members.3.description')}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamBio;
