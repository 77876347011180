import React, { Component } from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import PortofolioListImageBanner from "../../components/PortofolioListImageBanner";
import PortofolioListHeaderCategories from "../../components/PortofolioListHeaderCategories";
import PortofolioListCategoriesGrid from "../../components/PortofolioListCategoriesGrid";
import { Trans, useTranslation } from '../../lib/i18n';

const PortofolioListPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <PortofolioListImageBanner title={t('Portofolio.Title')} />
            <PortofolioListHeaderCategories />
            <PortofolioListCategoriesGrid />
            <SearchForm />
            <GetYourQuote title={<Trans i18nKey="Common.Let's start" />} />
            <Footer />
        </>
    );
};

export default PortofolioListPage;