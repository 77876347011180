import React from 'react';
import image1 from "../../assets/images/team/1.png";
import image2 from "../../assets/images/team/2.png";
import image3 from "../../assets/images/team/3.png";
import image5 from "../../assets/images/team/5.png";
import { useTranslation } from '../../lib/i18n';

const Team = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="commonSection team">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h4 className="sub_title">{t('HomePage.Team.Subtitle')}</h4>
                            <h2 className="sec_title">{t('HomePage.Team.Title')}</h2>
                            <p className="sec_desc">
                                {t('HomePage.Team.Description')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="team_slider">
                    <div className="singleTM">
                        <div className="tm_img">
                            <img src={image1} alt="" />
                        </div>
                        <div className="detail_TM">
                            <h5>{t('HomePage.Team.Members.0.name')}</h5>
                            <h6>{t('HomePage.Team.Members.0.post')}</h6>
                        </div>
                    </div>
                    <div className="singleTM">
                        <div className="tm_img">
                            <img src={image2} alt="" />
                        </div>
                        <div className="detail_TM">
                            <h5>{t('HomePage.Team.Members.1.name')}</h5>
                            <h6>{t('HomePage.Team.Members.1.post')}</h6>
                        </div>
                    </div>
                    <div className="singleTM">
                        <div className="tm_img">
                            <img src={image3} alt="" />
                        </div>
                        <div className="detail_TM">
                            <h5>{t('HomePage.Team.Members.2.name')}</h5>
                            <h6>{t('HomePage.Team.Members.2.post')}</h6>
                        </div>
                    </div>
                    <div className="singleTM">
                        <div className="tm_img">
                            <img src={image5} alt="" />
                        </div>
                        <div className="detail_TM">
                            <h5>{t('HomePage.Team.Members.3.name')}</h5>
                            <h6>{t('HomePage.Team.Members.3.post')}</h6>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Team;
