import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from '../../lib/i18n';
import Flags from 'country-flag-icons/react/3x2';

const getLanguage = lang => {
  const l = lang.toLocaleUpperCase();

  if (l === 'EN') return 'US';

  return l;
};

export const LocalizationFlag = () => {
  const [flagVisibility, setFlagVisibility] = useState(false);
  const {i18n, t} = useTranslation();

  const languages = useMemo(() => Object.keys(i18n.store.data), [i18n.store.data,]);
  const language = useMemo(() => languages.indexOf(i18n.language) > -1 ? i18n.language : i18n.options.fallbackLng?.[0],
    [languages, i18n.language, i18n.options.fallbackLng]);

  const showFlags = useCallback(() => setFlagVisibility(true), [setFlagVisibility]);
  const hideFlags = useCallback(() => setFlagVisibility(false), [setFlagVisibility]);

  const options = useMemo(() => languages.filter(lang => lang !== language).map(lang => ({
    lang,
    Icon: Flags[getLanguage(lang)]
  })), [languages, language]);

  const onChangeLanguage = useCallback((event) => {
    i18n.changeLanguage(event.currentTarget.dataset?.lang);
  }, [i18n]);

  const Flag = Flags[getLanguage(language)];

  return <div className="flag-container" onMouseEnter={showFlags} onMouseLeave={hideFlags}>
    <Flag title={t(language)}/>

    <div className={`${flagVisibility ? '' : 'hidden'}`}>
      {options.map(option => <div className="flag-element" key={option.lang} data-lang={option.lang}
                                  onClick={onChangeLanguage}>
        <option.Icon/>
      </div>)}
    </div>

  </div>;

};

export default LocalizationFlag;
