import React from 'react';

const Preloader = () => {
    return (
        <div className="preloader text-center">
            <div className="la-ball-circus la-2x">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Preloader;